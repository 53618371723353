import React from 'react';
import {
  ChakraProvider,
  Box,
  Heading,
  Link,
} from '@chakra-ui/react';
import {
  Routes,
  Route,
  Navigate,
  Link as RLink,
} from 'react-router-dom';
import theme from './theme';
// import Home from './components/Home';
import Infographic from './components/Infographic';
import View from './components/View';
import Edit from './components/Edit';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box display="flex" flexDirection="column" minH="100vh">
        <Box textAlign="center" fontSize="xl" bg="P12" color="G01" p="2">
          <Heading>
            <Link as={RLink} to="/"> Infografias</Link>
          </Heading>
        </Box>
        <Box w={{ base: '90%' }} bg="G01" mx="auto" my="5" height="100%" flexGrow="1">
          <Routes>
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/new" element={<Infographic />} />
            <Route path="/:infographic/view" element={<View />} />
            <Route path="/:infographic/edit" element={<Edit />} />
            <Route path="/*" element={<Navigate to="/new" />} />
          </Routes>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default App;
