import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSpinner from '../utils/useSpinner';
import Infographic from './Infographic';

const Edit = () => {
  const { infographic } = useParams();
  const [html, setHtml] = useState();
  const [Spinner] = useSpinner();
  const url = `${process.env.REACT_APP_INFOGRAPICS_URL}/${infographic}/index.html`;

  useEffect(() => {
    axios.get(url)
      .then(({ data }) => setHtml(data));
  }, []);

  return html ? (
    <Infographic data={{ html, id: infographic }} edit />
  ) : <Spinner />;
};

export default Edit;
