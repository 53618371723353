import {
  Box, Button, Link, Text,
} from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { Link as RLink, useParams } from 'react-router-dom';
import useSpinner from '../utils/useSpinner';
import Clipboard from './Clipboard';

const View = () => {
  const { infographic } = useParams();
  const [Spinner, hideSpinner] = useSpinner();
  const ref = useRef();
  const refDesktop = useRef();
  const [html, setHtml] = useState();
  const [height, setHeight] = useState(600);
  const [heightDesktop, setHeightDesktop] = useState(600);

  const url = `${process.env.REACT_APP_INFOGRAPICS_URL}/${infographic}/index.html`;

  const handleOnload = (w) => {
    hideSpinner();
    if (w === '360px') {
      setHeight(ref.current.contentWindow.document.body.scrollHeight + 10);
    } else {
      setHeightDesktop(refDesktop.current.contentWindow.document.body.scrollHeight + 10);
    }
  };

  useEffect(() => {
    axios.get(url)
      .then(({ data }) => setHtml(data));
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb="5">
        <Box>
          <Button variant="primary" size="sm">
            <Link as={RLink} to="/new"> Nueva infografia</Link>
          </Button>
        </Box>
        <Box>
          <Button variant="primary" size="sm">
            <Link as={RLink} to={`/${infographic}/edit`}> Editar</Link>
          </Button>
        </Box>
      </Box>
      <Clipboard text={`${url}?h=${heightDesktop}&hamp=${height}`} />

      <Box display="flex" flexDir={{ base: 'column', md: 'row' }}>
        <Box>
          <Text mt="4" mb="2" fontSize="sm" py="1">Vista mobile</Text>
          <Box position="relative" mb="5">
            <Spinner />
            <Box
              border="1px solid"
              borderColor="G04"
              as="iframe"
              ref={ref}
              width="360px"
              height={`${height}px`}
              srcDoc={html}
              title="infografia"
              onLoad={() => handleOnload('360px')}
              frameBorder="0"
            />
          </Box>
        </Box>
        <Box ml={{ md: '2' }}>
          <Text mt="4" mb="2" fontSize="sm" py="1">Vista Desktop</Text>
          <Box position="relative" mb="5">
            <Spinner />
            <Box
              border="1px solid"
              borderColor="G04"
              as="iframe"
              ref={refDesktop}
              width="834px"
              height={`${heightDesktop}px`}
              srcDoc={html}
              title="infografia"
              onLoad={() => handleOnload('834px')}
              frameBorder="0"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default View;
