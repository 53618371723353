import { Box, Spinner as ChakraSpinner } from '@chakra-ui/react';
import { useState } from 'react';

const useSpinner = () => {
  const [showSpinner, setShowSpinner] = useState(true);
  const hideSpinner = () => setShowSpinner(false);

  const Spinner = () => {
    if (!showSpinner) return null;
    return (
      <Box position="absolute" w="100%" display="flex" justifyContent="center">
        <ChakraSpinner />
      </Box>
    );
  };

  return [Spinner, hideSpinner];
};

export default useSpinner;
