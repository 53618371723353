import { CopyIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';
import { Box, IconButton, Text } from '@chakra-ui/react';

const Clipboard = ({ text }) => (
  <Box bg="gray.100" fontSize="sm" color="G09" display="flex" justifyContent="space-between" _hover={{ bg: 'gray.200' }} my="2">
    <Text py="1" px="3">
      <Text as="span">{text}</Text>
    </Text>
    <IconButton
      mr="3"
      onClick={() => navigator.clipboard.writeText(text)}
      size="sm"
      icon={<CopyIcon />}
    />
  </Box>
);

Clipboard.propTypes = {
  text: PropTypes.string,
};

export default Clipboard;
