import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box, Button, FormControl, FormErrorMessage, FormLabel, Heading, Textarea,
} from '@chakra-ui/react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const Infographic = ({ data = {}, edit = false }) => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setError,
  } = useForm({
    defaultValues: data,
  });

  const uploadFile = async ({ html }) => {
    try {
      const { data: rta } = await axios.post(`/api/upload${data.id ? `?id=${data.id}` : ''}`);
      const file = new Blob([html], { type: 'text/html' });

      await axios.put(
        rta.url,
        file,
        {
          headers: {
            'Content-type': 'text/html',
          },
        },
      );
      navigate(`/${rta.id}/view`);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      setError('root.serverError', {
        type: '400',
      });
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(uploadFile)}>
        <Heading size="lg">{edit ? 'Editar infografía' : 'Nueva infografía'}</Heading>
        {errors.root?.serverError && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>No se pudo guardar la infografía</AlertTitle>
            <AlertDescription>Regargue la página para continuar</AlertDescription>
          </Alert>
        )}
        <FormControl my="4" isInvalid={errors.editor}>
          <FormLabel fontSize="md">Ingrese el html</FormLabel>
          <Textarea
            mt="2"
            size="md"
            type="text"
            placeholder="Ingrese el html"
            rows={30}
            {...register('html', { required: true })}
            isDisabled={isSubmitting}
          />
          <FormErrorMessage>El campo cuerpo es requerido</FormErrorMessage>
        </FormControl>
        <Button type="submit" my="2" variant="primary" isLoading={isSubmitting}>Guardar</Button>
        {edit && <Button m="2" color="G09" bg="G01" onClick={() => navigate(`/${data.id}/view`)}>Cancelar</Button>}
      </form>
    </Box>
  );
};

Infographic.propTypes = {
  data: PropTypes.shape({
    html: PropTypes.string,
    id: PropTypes.string,
  }),
  edit: PropTypes.bool,
};
export default Infographic;
