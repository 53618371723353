import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme(
  {
    colors: {
      P01: '#cff3fe',
      P03: '#6fcafa',
      P06: '#0d6ccd',
      P07: '#0950ac',
      P08: '#05388a',
      P09: '#032872',
      P10: '#30374b',
      P11: '#192137',
      P12: '#000821',

      G01: '#fafafa',
      G02: '#f5f5f5',
      G04: '#e0e0e0',
      G05: '#bdbdbd',
      G06: '#9e9e9e',
      G07: '#757575',
      G08: '#616161',
      G09: '#424242',
      G10: '#212121',
    },
    styles: {
      global: () => ({
        'html, body': {
          backgroundColor: 'G01',
          color: 'G10',
          height: '100%',
        },
      }),
    },
    components: {
      Button: {
        variants: {
          primary: {
            color: 'P06',
            border: '2px',
            bg: 'G02',
            _hover: {
              bgColor: 'P07',
              color: 'G01',
              borderColor: 'P07',
            },
            _active: {
              bgColor: 'P08',
              borderColor: 'P08',
            },
          },
        },
      },
    },
  },
);

export default theme;
